import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import {
  makeIsPrevPlanSelected,
  makeSelectCommercialField,
  makeSelectConfigField,
} from 'Containers/App/selectors';
import { makeGetRecommendedHsaContribution } from 'Containers/CommercialRoutes/selectors';
import { makeSelectHsaField } from 'Containers/HsaPage/selectors';
import { makeSelectOverviewField } from 'Containers/OverviewPage/selectors';
import { makeGetBuilderGoSetting, makeSelectProfileField } from 'Containers/ProfilePage/selectors';
import { useProfileContext } from 'Contexts/profileContext';
import { FadeUp } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { Recommendation, SelectedSupplementalPlans } from 'Types/entities';
import { GetHsaRecommendationResponse } from 'Utils/apiTypes';
import {
  ERROR_PATH,
  HSA_PLUS_PATH,
  INTRO_PATH,
  OVERVIEW_PATH,
  PROFILE_PATH,
  RESULT_PATH,
  TAX_SAVINGS_PATH,
  VIEW_PLAN_PATH,
  WELCOME_PATH,
} from 'Utils/urls';

import { Buttons } from './Buttons';
import Profile from './Profile';
import { Results } from './Results';
import { CtaContainer, CtaHalo, CtaPosition } from './styled';

export interface CtaBarParentProps {
  path: string;
  isBuilderClientWithNoEligiblePlans: boolean;
  hasUnsavedProfileChanges: boolean;
}

export interface CtaBarStoreProps {
  selectedPlan: Recommendation | Record<string, never>;
  isCoverageWaived: boolean;
  selectedSupplementalPlans: SelectedSupplementalPlans;
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  fbsEnabled: boolean;
  hsaEnabled: boolean;
  taxSavingsEnabled: boolean;
  killMedRec: boolean;
  lockedHsaContribution: number | null;
  hsaContribution: number | null;
  recommendedHsaContribution: number | null;
  isIncentiveLoading: boolean;
  isPlanSelected: boolean;
}

export type CtaBarProps = CtaBarParentProps & CtaBarStoreProps;

export const CtaBar = ({
  path,
  isBuilderClientWithNoEligiblePlans,
  selectedPlan,
  isCoverageWaived,
  selectedSupplementalPlans,
  fbsEnabled,
  hsaEnabled,
  taxSavingsEnabled,
  killMedRec,
  hsaData,
  lockedHsaContribution,
  hsaContribution,
  recommendedHsaContribution,
  isIncentiveLoading,
  hasUnsavedProfileChanges,
  isPlanSelected,
}: CtaBarProps) => {
  const { getIsFormValid, getIsFormCompleted, shouldUpdateIncentives } = useProfileContext();
  const [isHsaUnlocked, setIsHsaUnlocked] = useState(false);
  const [isTaxSavingsUnlocked, setIsTaxSavingsUnlocked] = useState(false);
  const [isSupplementalUnlocked, setIsSupplementalUnlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Logic to show Halo Glow effect
  const isFormValid = getIsFormValid();
  const isFormUnlocked = getIsFormCompleted();
  const showProfileGlow = path === PROFILE_PATH && isFormValid && isFormUnlocked;
  const showHealthGlow =
    (path === RESULT_PATH || path === VIEW_PLAN_PATH) && (isPlanSelected || isCoverageWaived);
  const showGlow = showProfileGlow || showHealthGlow;

  // Disable button while incentives call is being made
  useEffect(() => {
    if (shouldUpdateIncentives) {
      setIsLoading(true);
    }
  }, [shouldUpdateIncentives]);

  useEffect(() => {
    if (path === HSA_PLUS_PATH && !isHsaUnlocked) {
      setIsHsaUnlocked(true);
    }
    if (path === TAX_SAVINGS_PATH && !isTaxSavingsUnlocked) {
      setIsTaxSavingsUnlocked(true);
    }
    if (path === OVERVIEW_PATH && !isSupplementalUnlocked) {
      setIsSupplementalUnlocked(true);
    }
    if (!isLoading) {
      setIsLoading(true);
    }
  }, [path]);

  useEffect(() => {
    let timer: number;

    if (isLoading) {
      timer = window.setTimeout(() => setIsLoading(false), 1500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isLoading]);

  if (
    !path ||
    path === WELCOME_PATH ||
    path === ERROR_PATH ||
    (path !== PROFILE_PATH && hasUnsavedProfileChanges)
  ) {
    return null;
  }

  return (
    <CtaPosition>
      <FadeUp delay={4000} isOpen={path !== WELCOME_PATH && path !== INTRO_PATH}>
        <PageLayout bg="--primary-red" page="cta-bar">
          <Box pos="relative" zIndex={0}>
            <CtaContainer data-testid="cta-container" profile={path === PROFILE_PATH ? 1 : 0}>
              {path === PROFILE_PATH ? (
                <Profile />
              ) : (
                <Results
                  path={path}
                  isHsaUnlocked={isHsaUnlocked}
                  isSupplementalUnlocked={isSupplementalUnlocked}
                  selectedPlan={selectedPlan}
                  isCoverageWaived={isCoverageWaived || isBuilderClientWithNoEligiblePlans}
                  selectedSupplementalPlans={selectedSupplementalPlans}
                  hsaContribution={hsaContribution}
                  recommendedHsaContribution={recommendedHsaContribution}
                  killMedRec={killMedRec}
                />
              )}
              <Buttons
                path={path}
                isLoading={isLoading || isIncentiveLoading}
                selectedPlan={selectedPlan}
                isCoverageWaived={isCoverageWaived}
                selectedSupplementalPlans={selectedSupplementalPlans}
                fbsEnabled={fbsEnabled}
                hsaEnabled={hsaEnabled}
                taxSavingsEnabled={taxSavingsEnabled}
                hsaData={hsaData}
                lockedHsaContribution={lockedHsaContribution}
              />
            </CtaContainer>

            {showGlow && <CtaHalo />}
          </Box>
        </PageLayout>
      </FadeUp>
    </CtaPosition>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, CtaBarStoreProps>({
  selectedPlan: makeSelectCommercialField('selectedHealthPlan'),
  isCoverageWaived: makeSelectCommercialField('isCoverageWaived'),
  selectedSupplementalPlans: makeSelectOverviewField('selected_plans'),
  recommendedHsaContribution: makeGetRecommendedHsaContribution(),
  lockedHsaContribution: makeSelectHsaField('lockedHsaContribution'),
  hsaData: makeSelectHsaField('hsaData'),
  fbsEnabled: makeSelectConfigField('is_fbs_enabled'),
  hsaEnabled: makeSelectConfigField('hsa_plus_enabled'),
  taxSavingsEnabled: makeSelectConfigField('show_tax_savings_page'),
  killMedRec: makeGetBuilderGoSetting('kill_med_rec'),
  hsaContribution: makeSelectHsaField('lockedHsaContribution'),
  isIncentiveLoading: makeSelectProfileField('incentiveSurveyIsLoading'),
  isPlanSelected: makeIsPrevPlanSelected(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(CtaBar);
